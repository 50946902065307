<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
:root {
  --date-position: 4px;
  --month-position: 22px;
  --year-position: 32px
}

input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  color: #fff;
  display: hidden;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  position: absolute !important;
  color: #000;
  left: calc(var(--date-position) + var(--month-position) + var(--year-position));
}

input[type="date"]::-webkit-datetime-edit-month-field {
  position: absolute !important;
  color: #000;
  left: calc(var(--date-position) + var(--month-position));
  border-inline: 1px solid black;
  padding-inline: 4px;
}


input[type="date"]::-webkit-datetime-edit-day-field {
  position: absolute !important;
  color: #000;
  left: var(--date-position);
}
</style>