<template>
    <v-text-field :dense="dense" type="text" v-model="input" @input="onInput" :option="{ currency: 'IDR' }"
        :disabled="disabled" :label="label" required></v-text-field>
</template>

<script>
export default {
    name: 'CustomInput',
    props: ['value', 'dense', 'disabled', 'allowZero', 'label',],
    data() {
        return {
            input: this.value,
        }
    },
    watch: {
        value: {
            handler(v) {
                this.input = this.$moneyFormat(v, false, this.allowZero);
            },
            deep: true
        }
    },
    mounted() {
        this.input = this.$moneyFormat(this.value, true, this.allowZero)
    },
    methods: {
        onInput() {
            let x = this.input.toString()
            this.$emit('input', x);
        },
    },
}
</script>