import axios from "axios";
import { setHeaderToken } from "@/utils/auth";

const state = {
  user: null,
  isLoggedIn: false,
};

const getters = {
  isLoggedIn(state) {
    return state.isLoggedIn;
  },
  user(state) {
    return state.user;
  },
};

const actions = {
  auth_login({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("http://localhost:4009/api/v1/auth/login", data)
        .then((response) => {
          console.log(response.data);
          commit("SET_USER", response.data.data);
          localStorage.setItem("token", response.data.data.token);
          setHeaderToken(response.data.data.token);

          resolve(response);
        })
        .catch((err) => {
          commit("RESET_USER");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("socket_token");
          reject(err);
        });
    });
  },
  auth_register({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/auth/local/register", data)
        .then((response) => {
          commit("SET_USER", response.data.user);
          localStorage.setItem("token", response.data.jwt);
          setHeaderToken(response.data.jwt);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async auth_logout({ commit }) {
    commit("RESET_USER");
    localStorage.removeItem("token");
  },
};

const mutations = {
  SET_USER(state, data) {
    state.user = data;
    state.isLoggedIn = true;
  },
  SET_PACKAGE(state, packages) {
    state.package = packages;
  },
  SET_SOCKET_TOKEN(state, socket_token) {
    state.socketToken = socket_token;
  },
  SET_ROLE(state, role) {
    state.role = role;
  },
  RESET_USER(state) {
    state.user = null;
    state.package = null;
    state.socketToken = null;
    state.isLoggedIn = false;
    state.role = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
