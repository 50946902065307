import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/modules/auth";
import product from "@/store/modules/product";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    product,
  },
  plugins: [createPersistedState()],
});
